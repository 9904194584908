import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import App from './App.vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/:videoId?',
      name: 'home',
      component: App,
    },
  ],
});

const app = createApp(App); // Corrected line
app.use(router);
app.mount('#app');
